/* eslint-disable no-undef */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, Tooltip } from 'antd';
import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import settingsIcon from 'images/illustrations/settings-icon.svg';
import helpIcon from 'images/illustrations/help-icon.svg';
import inviteIcon from 'images/illustrations/invite-icon.svg';
import ConditionalWrap from 'utils/ConditionalWrap';
import { showIntercom } from 'assets/helpers/helpers';
import usePaymentsPendingInflu from 'assets/CustomHooks/use-payments-pending-influ';

export function InfluLink({ closeProfileMenu, link }) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const onClickMobile = () => {
    switch (link.id) {
      case 2:
      case 9:
        navigate(link.link);
        break;
      case 6:
        window.open('https://intercom.help/heepsy/en', '_blank');
        break;
      case 7:
        showIntercom();
        break;
      default:
        break;
    }
    if (link.id !== 7) closeProfileMenu();
  };

  const [hasPendingPayment] = usePaymentsPendingInflu();

  return (
    <>
      {link.id === 6 && isMobile && (
        <Divider
          className="m-0"
          style={{
            background: '#E8EBEC',
          }}
        />
      )}

      <ConditionalWrap
        key={link.id}
        condition={!isMobile}
        wrap={(children) => (
          <Link
            to={link.link}
            onClick={closeProfileMenu}
            id={link.text === t('navbar_logged.dropdown.settings') ? '' : 'menu_settings'}
          >
            {children}
          </Link>
        )}
        mobileWrap={(children) => (
          <div role="button" className="profile-menu__button" onClick={onClickMobile}>
            {children}
          </div>
        )}
      >
        <div className="profile-menu__item">
          <div className="d-flex">
            {isMobile &&
              (link.text === t('navbar_logged.dropdown.help') ? (
                <QuestionCircleOutlined
                  className="mr-3 ml-0"
                  style={{
                    fontSize: 20,
                  }}
                />
              ) : (
                <div className="profile-menu__item-img">
                  <img
                    className="mr-3"
                    src={
                      link.text === t('navbar_logged.dropdown.settings')
                        ? settingsIcon
                        : link.id === 7
                        ? helpIcon
                        : inviteIcon
                    }
                    alt=""
                  />
                </div>
              ))}
            {link.text}
            {hasPendingPayment === 'has_pending_payments' && isMobile && link.id === 9 && (
              <>
                &nbsp;&nbsp;
                <Tooltip
                  title={
                    <div style={{ color: 'white' }}>
                      <Trans i18nKey={t('billing_details.banner.has_pending_payments')} />
                    </div>
                  }
                  zIndex={99999}
                  placement="bottom"
                  className="d-inline-flex align-items-center"
                  trigger={'click'}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ExclamationCircleFilled style={{ zIndex: 9999, position: 'relative' }} className="banner-alert" />
                </Tooltip>
              </>
            )}
          </div>
          {isMobile && link.id !== 7 && <div className="icon-chevron" />}
        </div>
      </ConditionalWrap>
    </>
  );
}
