import './AbTestsSwitch.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Select } from 'antd';
import { useMemo, useReducer, useEffect } from 'react';
import useTest from 'assets/CustomHooks/use-test';
import { setTestsCookie } from 'utils/abTest';
import { pickBy } from 'lodash';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const isTestEnvironement = process.env.REACT_APP_DEV_ENV === 'true';

const toggle = (toggled) => !toggled;

export default function AbTestsSwitch() {
  const [abTests, abTest] = useTest();
  const [bannerVisible, toggleBanner] = useReducer(toggle, isTestEnvironement);
  const [drawerOpen, toggleDrawer] = useReducer(toggle, false);

  const toggleDrawerAndBanner = () => {
    toggleBanner();
    toggleDrawer();
  };

  const formConfig = useMemo(() => {
    const activeTests = pickBy(abTests, (x) => x.active);
    return Object.entries(activeTests).map(([testKey, testData]) => {
      return {
        name: testKey,
        defaultValue: abTest[testKey],
        options: Object.keys(testData.values).map((option) => ({ value: option })),
      };
    });
  }, [abTest, abTests]);

  useEffect(() => {
    window.showTestDrawer = () => {
      const user = JSON.parse(localStorage.user || null)?.user;
      if (!user) return;
      const userEmail = user.email;
      const userEmailProvider = userEmail.split('@')[1];
      const userIsStaff = ['moonio.io', 'heepsy.com', 'gemssy.com', 'white-test.com'].includes(userEmailProvider);
      if (userIsStaff) toggleDrawer();
    };
    return () => {
      delete window.showTestDrawer;
    };
  }, []);

  const submitForm = (data) => {
    if (data) {
      setTestsCookie(data);
      window.location.reload();
    }
  };

  return (
    <>
      {bannerVisible && (
        <div
          className={classNames('-no-print ab_tests_switch__banner', { 'ab_tests_switch__banner--mobile': isMobile })}
        >
          <Button type="default" onClick={toggleDrawerAndBanner}>
            Modify AB tests
          </Button>
          <span className="ab_tests_switch__banner__close" onClick={toggleBanner}>
            <CloseOutlined />
          </span>
        </div>
      )}
      <Drawer
        rootClassName="ab_tests_switch__drawer"
        title="AB tests config"
        placement="right"
        closable={true}
        onClose={isTestEnvironement ? toggleDrawerAndBanner : toggleDrawer}
        open={drawerOpen}
        className="-no-print"
      >
        <Form onFinish={submitForm} layout="vertical">
          {formConfig.map((item) => (
            <Form.Item name={item.name} key={item.name} label={item.name} initialValue={item.defaultValue}>
              <Select options={item.options} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update AB tests & reload
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
