export const CREATORS_TABLE_COLUMN__NETWORKS = 'card_network';
export const CREATORS_TABLE_COLUMN__LIKES = 'likes';
export const CREATORS_TABLE_COLUMN__COMMENTS = 'comments';
export const CREATORS_TABLE_COLUMN__TOTAL_ENGAGEMENTS = 'totalEngagementCount';
export const CREATORS_TABLE_COLUMN__CAMPAIGN_ENGAGEMENT_PERCENTAGE = 'campaignEngagementPercentage';
export const CREATORS_TABLE_COLUMN__IMPRESSIONS = 'impressions';
export const CREATORS_TABLE_COLUMN__REACH = 'reach';
export const CREATORS_TABLE_COLUMN__TOTAL_MEDIA = 'totalMediaCount';
export const CREATORS_TABLE_COLUMN__YOUTUBE_VIDEOS = 'youtubeVideosCount';
export const CREATORS_TABLE_COLUMN__INSTAGRAM_POSTS = 'instagramPostsCount';
export const CREATORS_TABLE_COLUMN__TIKTOK_VIDEOS = 'titktokVideosCount';
export const CREATORS_TABLE_COLUMN__COST_PER_MILLE = 'costPerMille';
export const CREATORS_TABLE_COLUMN__SHARES = 'shares';
export const CREATORS_TABLE_COLUMN__CLICKS = 'clicks';

export const TABLE_PAGE_SIZE = 12;

export const CREATORS_TABLE_COLUMNS_CONFIG = [
  {
    key: CREATORS_TABLE_COLUMN__NETWORKS,
    isDefault: false,
    translationKey: 'reports.creators_section.table.networks_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__REACH,
    isDefault: false,
    translationKey: 'reports.creators_section.table.reach_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__IMPRESSIONS,
    isDefault: true,
    translationKey: 'reports.creators_section.table.impressions_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__TOTAL_MEDIA,
    isDefault: true,
    translationKey: 'reports.creators_section.table.total_media_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__INSTAGRAM_POSTS,
    network: 'instagram',
    isDefault: true,
    translationKey: 'reports.creators_section.table.instagram_posts_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__YOUTUBE_VIDEOS,
    network: 'youtube',
    isDefault: true,
    translationKey: 'reports.creators_section.table.youtube_videos_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__TIKTOK_VIDEOS,
    network: 'tiktok',
    isDefault: true,
    translationKey: 'reports.creators_section.table.tiktok_videos_column',
  },
  { key: CREATORS_TABLE_COLUMN__LIKES, isDefault: true, translationKey: 'reports.creators_section.table.likes_column' },
  {
    key: CREATORS_TABLE_COLUMN__COMMENTS,
    isDefault: true,
    translationKey: 'reports.creators_section.table.comments_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__SHARES,
    isDefault: true,
    translationKey: 'reports.creators_section.table.shares_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__CLICKS,
    isDefault: true,
    translationKey: 'reports.creators_section.table.clicks_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__TOTAL_ENGAGEMENTS,
    isDefault: true,
    translationKey: 'reports.creators_section.table.total_engagements_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__CAMPAIGN_ENGAGEMENT_PERCENTAGE,
    isDefault: true,
    translationKey: 'reports.creators_section.table.campaign_engagement_percentage_column',
  },
  {
    key: CREATORS_TABLE_COLUMN__COST_PER_MILLE,
    isDefault: true,
    translationKey: 'reports.sales_section.cost_per_mille',
  },
];

export const OVERVIEW_METRICS_CONFIG = [
  {
    key: 'salesCount',
    isDefault: false,
    translationKey: 'reports.sales_section.sales_count',
    toolTipTranslationKey: 'reports.sales_section.tooltip_sales_with_shopify',
    metricType: 'count',
    section: 'sales',
  },
  {
    key: 'salesRevenue',
    isDefault: true,
    translationKey: 'reports.sales_section.sales_revenue',
    toolTipTranslationKey: 'reports.sales_section.tooltip_revenue_with_shopify',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'totalCost',
    isDefault: true,
    translationKey: 'reports.sales_section.total_cost',
    toolTipTranslationKey: 'reports.sales_section.total_cost_tooltip',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'costPerMille',
    isDefault: true,
    translationKey: 'reports.sales_section.cost_per_mille',
    toolTipTranslationKey: 'reports.sales_section.cost_per_mille_tooltip',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'costPerClick',
    isDefault: true,
    translationKey: 'reports.sales_section.cost_per_click',
    toolTipTranslationKey: 'reports.sales_section.cost_per_click_tooltip',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'returnOfInvestmentPercentage',
    isDefault: true,
    translationKey: 'reports.sales_section.return_of_investment',
    toolTipTranslationKey: 'reports.sales_section.return_of_investment_tooltip',
    metricType: 'percent',
    section: 'sales',
  },
  {
    key: 'costPerEngagement',
    isDefault: false,
    translationKey: 'reports.sales_section.cost_per_engagement',
    toolTipTranslationKey: 'reports.sales_section.cost_per_engagement_tooltip',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'costPerSale',
    isDefault: false,
    translationKey: 'reports.sales_section.cost_per_sale',
    toolTipTranslationKey: 'reports.sales_section.cost_per_sale_tooltip',
    metricType: 'money',
    section: 'sales',
  },
  {
    key: 'likes',
    isDefault: true,
    translationKey: 'reports.engagement_section.likes',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'comments',
    isDefault: true,
    translationKey: 'reports.engagement_section.comments',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'shares',
    isDefault: true,
    translationKey: 'reports.engagement_section.shares',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'totalMediaCount',
    isDefault: false,
    translationKey: 'reports.engagement_section.total_media',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'instagramPostsCount',
    network: 'instagram',
    isDefault: false,
    translationKey: 'reports.engagement_section.instagram_posts',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'youtubeVideosCount',
    network: 'youtube',
    isDefault: false,
    translationKey: 'reports.engagement_section.youtube_videos',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'titktokVideosCount',
    network: 'tiktok',
    isDefault: false,
    translationKey: 'reports.engagement_section.titktok_videos',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'totalEngagementCount',
    isDefault: false,
    translationKey: 'reports.engagement_section.total_engagement_title',
    toolTipTranslationKey: 'reports.engagement_section.total_engagement_tooltip',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'campaignEngagementPercentage',
    isDefault: false,
    translationKey: 'reports.engagement_section.campaign_engagement_percentage_title',
    toolTipTranslationKey: 'reports.engagement_section.campaign_engagement_percentage_tooltip',
    metricType: 'percent',
    section: 'engagement',
  },
  {
    key: 'clicks',
    isDefault: false,
    translationKey: 'reports.engagement_section.clicks',
    toolTipTranslationKey: 'reports.engagement_section.clicks_tooltip',
    metricType: 'count',
    section: 'engagement',
  },
  {
    key: 'totalEstimatedImpressions',
    isDefault: true,
    translationKey: 'reports.overview_section.impressions',
    toolTipTranslationKey: 'reports.overview_section.impressions_tooltip',
    metricType: 'count',
    section: null,
  },
  {
    key: 'totalEstimatedReach',
    isDefault: true,
    translationKey: 'reports.overview_section.estimated_reach',
    toolTipTranslationKey: 'reports.overview_section.estimated_reach_tooltip',
    metricType: 'count',
    section: null,
  },

  {
    key: 'creatorsCount',
    isDefault: false,
    translationKey: 'reports.creators_section.creators_count',
    toolTipTranslationKey: 'reports.creators_section.creators_count_tooltip',
    metricType: 'count',
    section: 'creators',
  },
  {
    key: 'socialMediaAccountsCount',
    isDefault: false,
    translationKey: 'reports.creators_section.social_media_accounts_count',
    toolTipTranslationKey: 'reports.creators_section.social_media_accounts_count_tooltip',
    metricType: 'count',
    section: 'creators',
  },
  {
    key: 'totalFollowersCount',
    isDefault: false,
    translationKey: 'reports.creators_section.total_followers_count',
    toolTipTranslationKey: 'reports.creators_section.total_followers_count_tooltip',
    metricType: 'count',
    section: 'creators',
  },
];

export const CREATORS_TABLE_COLUMNS = CREATORS_TABLE_COLUMNS_CONFIG.map(({ key }) => key);
export const DEFAULT_CREATORS_TABLE_COLUMNS = CREATORS_TABLE_COLUMNS_CONFIG.filter(({ isDefault }) => isDefault).map(
  ({ key }) => key,
);

export const OVERVIEW_METRICS = OVERVIEW_METRICS_CONFIG.map(({ key }) => key);
export const DEFAULT_OVERVIEW_METRICS = OVERVIEW_METRICS_CONFIG.filter(({ isDefault }) => isDefault).map(
  ({ key }) => key,
);

export const MIN_OVERVIEW_METRICS_TO_SHOW = 1;
export const MAX_OVERVIEW_METRICS_TO_SHOW = 12;

export const DEFAULT_REPORT_CURRENCY = 'eur';
