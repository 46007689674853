import { useMemo } from 'react';
import { manageTestsCookie, getCookie } from 'utils/abTest';
const abTests = require('../../utils/abTests.json')

export default function useTest() {
  manageTestsCookie();
  const abTest = getCookie('ab_tests');

  return useMemo(() => {
    return [abTests, abTest];
  }, [abTest]);
}
