import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import logo from 'images/logoDark.svg';
import { LanguageDropdown } from 'components/Navbar/components/LanguageDropdown/LanguageDropdown';

export function Header({ setDropdownOpen, dropdownOpen }) {
  return (
    <header
      className={classNames('home-header', {
        'home-header--dropdown-open': dropdownOpen,
      })}
    >
      <Link to="/">
        <div>
          <img src={logo} loading="lazy" alt="Heepsy's Logo" className="home-header--logo" />
        </div>
      </Link>
      {isMobile && <LanguageDropdown />}
    </header>
  );
}
