const { pickBy } = require('lodash');
const abTests = require('./abTests.json');

export function getCookie(name, fromTests = true) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return JSON.parse(parts.pop().split(';').shift());
  else return fromTests ? {} : null;
}

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export function setTestsCookie(data) {
  const oneYearInSeconds = 365 * 24 * 60 * 60;
  let domain;
  if (process.env.REACT_APP_URL.includes('.heepsy.com')) {
    domain = ';domain=.heepsy.com';
  } else {
    domain = '';
  }
  document.cookie = `ab_tests=${JSON.stringify(data)}${domain};max-age=${oneYearInSeconds};path=/`;
}

export function setCookie(name, time) {
  let domain;
  if (process.env.REACT_APP_URL.includes('.heepsy.com')) {
    domain = ';domain=.heepsy.com';
  } else {
    domain = '';
  }
  document.cookie = `${name}=${true}${domain};max-age=${time};path=/`;
}

export function manageTestsCookie() {
  let ab_tests = getCookie('ab_tests');

  const ongoingTests = pickBy(abTests, (v, k) => v.active);
  const ongoingAppTests = pickBy(abTests, (v, k) => v.active && !v.statics);
  const testsMissing = Object.keys(ongoingAppTests).filter((elem) => !Object.keys(ab_tests).includes(elem));
  const testsOld = Object.keys(ab_tests).filter((elem) => Object.keys(abTests).includes(elem) && !Object.keys(ongoingTests).includes(elem));

  if (!testsMissing.length && !testsOld.length) return;

  for (let test of testsMissing) {
    const possibleValues = Object.keys(ongoingAppTests[test].values);
    ab_tests[test] = possibleValues[getRandomInt(possibleValues.length)];
  }

  for (let test of testsOld) {
    delete ab_tests[test];
  }

  setTestsCookie(ab_tests);
}
