import { createContext, useState, useMemo, useContext } from 'react';

const SearchItems = createContext();

export function useSearchItemsContext() {
  return useContext(SearchItems);
}

export function SearchItemsProvider({ children }) {
  const [searchItems, setSearchItems] = useState([]);
  const [searchLocation, setSearchLocation] = useState('');
  const [coordinates, setCoordinates] = useState('');
  const value = useMemo(() => {
    return { searchItems, setSearchItems, searchLocation, setSearchLocation, coordinates, setCoordinates };
  }, [searchItems, setSearchItems, searchLocation, setSearchLocation, coordinates, setCoordinates]);

  return <SearchItems.Provider value={value}>{children}</SearchItems.Provider>;
}
