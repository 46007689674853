import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { Divider, Segmented, Typography, message } from 'antd';
import useGeoLocation from 'react-ipgeolocation';

import userService from 'services/users.service';
import { SignupForm } from './components/Signup/SignupForm';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ForgotPassword from './components/ForgotPassword';
import { LoginForm } from './components/LoginForm/LoginForm';
import codeToCountry from 'assets/config/codeToCountry';
import { getSegmentAnoymousId } from 'utils/instrumentation';

import './../login-signup.scss';
import pushEventToGtmDataLayer from 'helpers/pushEventToGtmDataLayer';

const { Text } = Typography;

const LoginSignupPage = ({ toggleVisibility, isLoginPage, isSignupPage, toggleLogin, isModal }) => {
  const { t } = useTranslation('common');

  const IpLocation = useGeoLocation();

  const selectedLocale = localStorage.getItem('lang') === 'es' ? 'es_ES' : 'en_US';

  const [isForgotVisible, setForgotVisible] = useState(false);
  const [googleButtonWidth, setGoogleButtonWidth] = useState();
  const [accountTypeInputValue, setAccountTypeInputValue] = useState('brand');
  const [signupError, setSignupError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const toggleForgot = () => {
    setForgotVisible(!isForgotVisible);
    toggleVisibility();
  };

  const googleButtonContainer = useRef();
  useEffect(() => {
    const container = googleButtonContainer.current;

    if ('ResizeObserver' in window && container) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];
            const width = contentBoxSize.inlineSize;
            setGoogleButtonWidth(`${width}px`);
          }
        }
      });
      resizeObserver.observe(container);
      return () => resizeObserver.unobserve(container);
    }
  }, [googleButtonContainer]);

  useEffect(() => {
    setSignupError(false);
  }, [isSignupPage]);

  const responseGoogleSuccess = async (googleResponse) => {
    const rootElem = document.getElementById('root');

    const data = {
      email: jwt_decode(googleResponse.credential).email,
      type: 'google_oauth',
      account_type: accountTypeInputValue,
      url: location.pathname + location.search,
      country: codeToCountry[IpLocation?.country],
      lang: localStorage.getItem('lang'),
      isSignupPage,
    };

    const segmentCookie = getSegmentAnoymousId();
    if (segmentCookie) {
      data.segmentCookie = segmentCookie;
    }

    const response = await userService.signup(data);
    const accountType = response?.data?.account_type || accountTypeInputValue;
    const alreadyExists = response?.data?.already_exists;

    if (response.status !== 400) {
      toggleVisibility();
    }

    if (response.status === 200) {
      if (accountType === 'brand' && !alreadyExists) {
        pushEventToGtmDataLayer('brands_agencies_sign_up', {
          user_email: response?.data?.email,
          user_id: response?.data?.id,
        });
      }
      if (!isModal) {
        const url = accountType === 'brand' ? '/creators' : '/opportunities';

        rootElem.classList.remove('-no-vertical-menu');
        return navigate(url);
      } else {
        rootElem.classList.remove('-no-vertical-menu');
        window.location.reload();
      }
    } else if (response.status === 409) {
      message.error(t('signup_page.error_email'));
    } else if (response.status === 400) {
      setSignupError(true);
    } else {
      message.error(t('lists.error'));
    }
  };

  const responseGoogleError = (response) => {
    console.error(response);
  };

  return (
    <HelmetProvider>
      {!isModal && (
        <Helmet>
          <title>
            {isSignupPage
              ? 'Sign up for free | End-to-end creator management | Heepsy'
              : 'Log in | End-to-end creator management platform | Heepsy'}
          </title>
          <meta
            name="description"
            content="Heepsy offers a comprehensive set of tools for brands and creators to connect, and all the necessary features to run hassle-free, successful collaborations."
          />
        </Helmet>
      )}
      <div className={classNames('login-signup__container', { '-is-modal': isModal })}>
        {!isModal && (
          <div className="login-signup__container-title">
            {isLoginPage && t('login_page.title')}
            {isSignupPage && t('signup_page.title')}
          </div>
        )}

        {isSignupPage && (
          <div>
            <p className="login-signup__container-type">{t('signup_form.account_type')}</p>
            <Segmented
              options={[
                { label: t('signup_form.type_1'), value: 'brand' },
                { label: t('signup_form.type_2'), value: 'influencer' },
              ]}
              value={accountTypeInputValue}
              block
              onChange={(value) => {
                setAccountTypeInputValue(value);
              }}
              className="mb-4"
            />
            <Divider />
          </div>
        )}
        <div
          ref={googleButtonContainer}
          className="d-flex justify-content-center w-100 mt-1 flex-column"
          key={isSignupPage}
        >
          <GoogleLogin
            onSuccess={responseGoogleSuccess}
            onError={responseGoogleError}
            text="continue_with"
            locale={selectedLocale}
            width={googleButtonWidth}
          />
          {signupError && <Text type="danger">{t('signup_page.error_400')}</Text>}
        </div>
        <div className="login-signup__container-divider mt-6">
          <span>{t('login_page.divider')}</span>
        </div>

        {isLoginPage && (
          <LoginForm
            toggleForgot={toggleForgot}
            isModal={isModal}
            toggleLogin={toggleLogin}
            toggleVisibility={toggleVisibility}
            isLoginPage={isLoginPage}
          />
        )}

        {isSignupPage && (
          <SignupForm
            openModal={toggleLogin}
            isSignupPage={isSignupPage}
            isModal={isModal}
            accountType={accountTypeInputValue}
          />
        )}
      </div>
      <ForgotPassword visible={isForgotVisible} toggleVisibility={toggleForgot} />
    </HelmetProvider>
  );
};

export default React.memo(LoginSignupPage);
